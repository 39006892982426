//@TODO: Este archivo deberia moverse a src/domain/model/endpoints.ts
//       La idea es mejorar la lectura y mantenimiento del codigo otorando un
//       acorde con la arquitectura de la aplicacion para las url de los
//       endpoints del backend
export const API = process.env.NEXT_PUBLIC_API;
export const INTERNAL = `${API}/internal`;
export const OPPORTUNITIES = `${INTERNAL}/opportunities`;
export const OPPORTUNITY = `${INTERNAL}/opportunity`;
export const OPPORTUNITIES_ACCOUNT = `${INTERNAL}/opportunities-by-account`;
export const CREATE_UNCHECK_PRODUCTS = `${OPPORTUNITY}/create-uncheck-products`;
export const ACCOUNTS = `${INTERNAL}/accounts`;

export const SERVICE = `${INTERNAL}/service`;
export const SERVICE_UPDATE = `${INTERNAL}/update-service`;
export const SERVICE_UPDATE_PRODUCT = `${INTERNAL}/update-service-product/product-id`;
export const SERVICE_UPDATE_MILESTONE = `${INTERNAL}/update-service-milestone`;
export const SERVICE_MOVE_PHASE = `${SERVICE}/move-phase`;
export const SERVICE_APPROVE = `${SERVICE}/approve`;
export const SERVICE_CARD_ID = `${SERVICE}/by-card-id`;
export const SERVICES = `${INTERNAL}/services`;
export const SERVICES_BY_USER = `${SERVICES}/by-user`;

export const SUBSIDIARY = `${INTERNAL}/subsidiary`;

export const MARGINS = `${INTERNAL}/margins`;
export const PRODUCT = `${INTERNAL}/product`;
export const PRODUCT_ASSIGN_EMPLOYEE = `${PRODUCT}/assign-employee`;
export const PRODUCT_UNASSIGN_EMPLOYEE = `${PRODUCT}/unassigned-employee`;
export const DELETE_UNCHECK_BY_OPPORTUNITY = `${PRODUCT}/delete-uncheck-by-opportunity`;
export const PRODUCT_UPDATE_COUNTRY = `${PRODUCT}/update-country`;
export const PRODUCT_REPLACE_EMPLOYEE = `${PRODUCT}/replace-employee`;

export const TAX = `${INTERNAL}/tax`;
export const EXCHANGE = `${TAX}/exchange`;

export const USER_API = `${INTERNAL}/user`;
export const USER_UPDATE_TOKEN = `${USER_API}/update-token`;

export const WORKFLOW = `${INTERNAL}/workflow`;
export const TASKS = `${WORKFLOW}/my-tasks`;
export const TASKS_RESUME = `${WORKFLOW}/my-tasks-resume`;
export const TASK_MOVE_PHASE = `${WORKFLOW}/move-task`;
export const TASK_BY_ID = `${WORKFLOW}/by-id`;
export const TASKS_BY_SERVICE = `${WORKFLOW}/by-service-id`;
export const APPROVE_TASK_ASSIGNMENT = `${WORKFLOW}/approve-task-assignment`;
export const APPROVE_TASK_ASSIGNMENT_BY_FINANCIAL = `${WORKFLOW}/approve-task-assignment-by-financial`;
export const REJECT_TASK_ASSIGNMENT = `${WORKFLOW}/reject-task-assignment`;
export const APPROVE_TASK_RENEW_END = `${WORKFLOW}/approve-task-renew-or-end-assignment`;
export const REJECT_TASK_RENEW_END = `${WORKFLOW}/reject-task-renew-or-end-assignment`;
export const APPROVE_TASK_RENEW_END_FINANCIAL = `${WORKFLOW}/approve-task-renew-or-end-assignment-financial`;
export const REJECT_TASK_RENEW_END_FINANCIAL = `${WORKFLOW}/reject-task-renew-or-end-assignment-financial`;
export const APPROVE_TASK_REPLACE_BY_COMERCIAL = `${WORKFLOW}/approve-task-replacement-assignment-by-comercial`;
export const REJECT_TASK_REPLACE_BY_COMERCIAL = `${WORKFLOW}/reject-task-replacement-assignment-by-comercial`;
export const APPROVE_TASK_REPLACE_BY_FINANCIAL = `${WORKFLOW}/approve-task-replacement-assignment-by-financial`;
export const REJECT_TASK_REPLACE_BY_FINANCIAL = `${WORKFLOW}/reject-task-replacement-assignment-by-financial`;

export const PHASE = `${INTERNAL}/phase`;

export const EMPLOYEES = `${INTERNAL}/employees`;
export const EMPLOYEES_BY_ROLE = `${EMPLOYEES}/by-role-name`;
export const EMPLOYEES_RENEWALS_EARLY_TERM = `${EMPLOYEES}/renewals-early-term`;

export const COUNTRY = `${INTERNAL}/country`;
export const COUNTRY_WITH_CURRENCY = `${COUNTRY}/with-currency`;
export const COUNTRY_BY_CODE = `${COUNTRY}/by-code`;

export const ASSIGNMENT = `${INTERNAL}/assignment`;
export const END_RENEW_CHANGE = `${ASSIGNMENT}/end-renew-change-unit-price`;
export const NO_RENEW = `${ASSIGNMENT}/no-renew`;

// Invoice-Draft requests
export const INVOICE_DRAFT = `${INTERNAL}/invoice-draft`;

export const CLIENT_ID = process.env.GOOGLE_CLIENT_ID;
export const CLIENT_SECRET = process.env.GOOGLE_CLIENT_SECRET;
export const NEXTAUTH_SECRET = process.env.NEXTAUTH_SECRET;
export const NEXTAUTH_URL = process.env.NEXTAUTH_URL;
export const GTAG_ID = process.env.NEXT_PUBLIC_GTAG;
export const DEFAULT_NAMESPACES = ['common', 'components'];
export const ASSIGNMENTS_MAKERS_BY_ACCOUNT_ID = `${ASSIGNMENT}/makers-by-account-id`;

export const ASSIGNMENT_HISTORY = `${INTERNAL}/assignment-history`;

export const NEXT_PUBLIC_ENVIRONMENT = process.env.NEXT_PUBLIC_ENVIRONMENT as
  | 'local'
  | 'dev'
  | 'prod';
export const IS_ASSIGNMENT_REFACTOR_ENABLED = false;
